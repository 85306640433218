exports.components = {
  "component---src-pages-404-tsx": () => import("./../../../src/pages/404.tsx" /* webpackChunkName: "component---src-pages-404-tsx" */),
  "component---src-pages-about-tsx": () => import("./../../../src/pages/about.tsx" /* webpackChunkName: "component---src-pages-about-tsx" */),
  "component---src-pages-blog-index-tsx": () => import("./../../../src/pages/blog/index.tsx" /* webpackChunkName: "component---src-pages-blog-index-tsx" */),
  "component---src-pages-blog-mdx-frontmatter-slug-tsx-content-file-path-src-content-posts-04-2023-artificial-intelligence-mdx": () => import("./../../../src/pages/blog/{Mdx.frontmatter__slug}.tsx?__contentFilePath=/opt/build/repo/src/content/posts/04-2023-artificial-intelligence.mdx" /* webpackChunkName: "component---src-pages-blog-mdx-frontmatter-slug-tsx-content-file-path-src-content-posts-04-2023-artificial-intelligence-mdx" */),
  "component---src-pages-blog-mdx-frontmatter-slug-tsx-content-file-path-src-content-projects-blush-mdx": () => import("./../../../src/pages/blog/{Mdx.frontmatter__slug}.tsx?__contentFilePath=/opt/build/repo/src/content/projects/blush.mdx" /* webpackChunkName: "component---src-pages-blog-mdx-frontmatter-slug-tsx-content-file-path-src-content-projects-blush-mdx" */),
  "component---src-pages-contact-tsx": () => import("./../../../src/pages/contact.tsx" /* webpackChunkName: "component---src-pages-contact-tsx" */),
  "component---src-pages-index-tsx": () => import("./../../../src/pages/index.tsx" /* webpackChunkName: "component---src-pages-index-tsx" */),
  "component---src-pages-projects-tsx": () => import("./../../../src/pages/projects.tsx" /* webpackChunkName: "component---src-pages-projects-tsx" */)
}

